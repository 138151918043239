import React from 'react';
import ProjectUsers from './projectusers';

const  ProjectPage = (url) =>
{
    const project_id = url.match.params.project_id;
    return (
        <ProjectUsers project_id = {project_id}/>
    )
      
}

export default ProjectPage;