import React from "react";
import ProjectList from "./project_list";

export let valueArray = ["FLEX-air", "Ty4", "SWFU"];

class Home extends React.Component
{
    render() {
        return (
            <div className='home'>
            <ProjectList values={valueArray}/>
            </div>
        );
    }
}

export default Home;
