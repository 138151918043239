import { List, ListItem } from '@material-ui/core';
import React from 'react';
import { UsersProjects } from '../models';

import useFetch from '../useFetch';
  
function ListItemLink(props) {
  return <ListItem button component="a" {...props} />;
}

const ProjectUser = (props) =>
{
    var linkName = "/users/" + props.data.id;
    return <ListItemLink href={linkName}>{props.data.FirstName + " " + props.data.LastName}</ListItemLink>
}

const ProjectUsers = (url) =>
{
  const {data, isPending, error} = useFetch (UsersProjects, p => p.projects.id === url.project_id);
 
  if (data)
  {
    const items = data.map((item, index) => {
          return <ProjectUser data={item.users}/>
      }); 

      return (
      <List>{items}</List>
      );
  }
  else
  {
      if (isPending)
        return (<h2> Loading...</h2>);
  }
  return (
    <div>{error}</div>
  )
}

export default ProjectUsers;