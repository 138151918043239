
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import { Button, TextField } from '@material-ui/core';

export default function PopupDialogText(props) {
    const { onClose, open, title } = props;
    const [name, SetName] = useState("");

    const handleClose = () => {
      onClose(name);
    };
  
    const handleChange = (event) => {
        SetName(event.target.value);
      };
      
    return (
      <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
        <DialogTitle id="simple-dialog-title">{title}</DialogTitle>
        <TextField
          required
          id="value"
          label="Required"
          defaultValue=""
          variant="filled"
          onChange={handleChange}
        /> 
        <Button onClick={()=>{handleClose()}}>Create</Button>
        <Button onClick={()=>{SetName("");handleClose()}}>Cancel</Button>
      </Dialog>
    );
  }
  
  PopupDialogText.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
  };
  
  