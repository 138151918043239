import React, { useState } from 'react';

import { Button, Checkbox, List, ListItem, ListItemIcon, Menu, MenuItem } from '@material-ui/core';
import useFetch from '../useFetch';
import { DataStore } from 'aws-amplify';
import {Projects} from '../models'
import PopupDialogText from './PopupDialogText';

function ActionMenu(props) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [openProjectName, setOpenProjectName] = React.useState(false);


    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
      setAnchorEl(null);
    };
    const handleCreate = () => {
       handleEnterProjectNameOpen();
    }
  
    const handleEnterProjectNameOpen = () => {
        setOpenProjectName(true);
      };
    
    const handleProjectNameClose = (value) => {
        setOpenProjectName(false);
        if (value)
        {
            DataStore.save(
                new Projects({
                    Name: value,
                })
            )
            .then( () => {
                setAnchorEl(null);
            });
        }
    }   
    return (
      <div>
        <Button variant="contained" aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
          Actions
        </Button>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem onClick={handleCreate}>Create Project</MenuItem>
          <MenuItem onClick={handleClose}>Edit Project</MenuItem>
          <MenuItem onClick={handleClose}>Delete Project</MenuItem>
        </Menu>
        <PopupDialogText open={openProjectName} title="Enter New Project Name" onClose={handleProjectNameClose} />
      </div>
    );
  }

function ListItemLink(props) {
    return <ListItem button component="a" {...props} />;
}

class ProjectList extends React.Component
{
    render() {
        return (
            <div className='project_list'>
                <h1 align='left'>Projects</h1>
                <ProjectArray values={this.props.values}/>
            </div>            
        );
    }
}


const SelectCheckBox = (labelId) => 
{
    const [checked, setChecked] = useState(false);
    
    const handleToggle = (value) => () => {
        const newChecked = !checked;
    
        setChecked(newChecked);
      };

    
    return (
    
        <ListItemIcon onClick={handleToggle()}>
            <Checkbox 
                edge="start"
                checked={checked}
                tabIndex={-1}
                disableRipple
            />
        </ListItemIcon>
    )
}

const ProjectArray = () =>
{
    const {data, isPending, error} = useFetch(Projects, null);

    if(data)
    {
        const items = data.map((item, index) => {
        // Only do this if items have no stable IDs
            var linkName = "/projects/" + item.id;
            return <ListItem key={index}><SelectCheckBox labelId = "test"/><ListItemLink align="left" href={linkName}>{item.Name}</ListItemLink></ListItem>;
        }); 

        return (
        <div className="project_list">
            <div>
                <div align="right"><ActionMenu /></div>
                <List>{items}</List>
            </div>
        </div>
        )
    }
    return (
        <div className="project_list">
            {error && <div> {error} </div>}
            {isPending && <div> Loading... </div>}
        </div>
    )
}
  
export default ProjectList;