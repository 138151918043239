// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';



const { ProjectAssignments, Projects, UsersProjects, Users, Wages, Weeks } = initSchema(schema);

export {
  ProjectAssignments,
  Projects,
  UsersProjects,
  Users,
  Wages,
  Weeks
};