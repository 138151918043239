import { useEffect, useState } from "react"
import { DataStore } from 'aws-amplify';

const useFetch =(queryObj, variablesObj) =>{
    const [data, setData] = useState(null);
    const [isPending, setIsPending] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {

        setTimeout(() => {
            var pmse = null;
            pmse = DataStore.query(queryObj);
            pmse.then(res => {
                if (variablesObj)
                    return res.filter(variablesObj);
                else
                    return res;
            })
            .then(data => {
                setData(data);  
                setIsPending(false);
                setError(null);                
            })
            .catch(err => {
                setIsPending(false);
                setError(err.errors);                
            })
        },1000); 
    }, [queryObj, variablesObj]);
    return {data, isPending, error}
}

export default useFetch;
