import './App.css';
import Users from './Elements/users';
import Home from './Elements/home';
import ProjectPage from './Elements/projects';

import { BrowserRouter as Router, NavLink, Switch, Route } from 'react-router-dom';
import { Paper } from '@material-ui/core';
import { withAuthenticator, AmplifySignOut } from '@aws-amplify/ui-react';

function App() {
  return (
    <div className="App">
      
      <div>
        <AmplifySignOut style={{float:"right"}}/>
        <h1 align='left'>KROME Tracker</h1>
      </div>

      <Router>
        <Navigation />
        <Paper  elevation={2}>
          <Switch>
            <Route exact path='/' component={Home}/>
            <Route exact path='/users' component={Users}/>
            <Route exact path='/projects/:project_id' component={ProjectPage}/>
          </Switch>
        </Paper>
      </Router>
    </div>
  );
}

const Navigation = () => (
    <nav>
      <ul>
        <li><NavLink to='/'>Home</NavLink></li>
        <li><NavLink to='/users'>Users</NavLink></li>
        <li><NavLink to='/projects'>Projects</NavLink></li>
      </ul>
    </nav>

);


export default withAuthenticator(App);
